import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import weirdwoodManor from './routes/weirdwood-manor';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** About Us page, note the change from about-us to aboutUs. */
  weirdwoodManor
});

/** Load Events */
jQuery(document).on('turbolinks:render ready', e => {
  routes.loadEvents();
});
