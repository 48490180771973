import { ScrollListener } from 'pickles';

export default {
  init() {
    console.log('home JS');

    // scroll listener

    new ScrollListener(() => {
      const scrollAmount = $(window).scrollTop();
      $('body').toggleClass('scrolled', scrollAmount > 100);
    });
    $(window).scroll();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
