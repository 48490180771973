import Loading from '../lib/loading';
import { ScrollEffects, AjaxForms } from 'pickles';
import FB from '../lib/fb';

export default {
  init() {
    console.log('common JS');

    window.$ = window.$ || jQuery;

    // JavaScript to be fired on all pages
    //console.log('common')

    Loading.init();

    const scrollEffects = new ScrollEffects();
    const ajaxForms = new AjaxForms({ selector: 'form.ajax' });

    FB.init();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};
