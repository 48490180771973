import { ScrollListener } from 'pickles';

export default {
  init() {
    console.log('weirdwood-manor JS');

    jQuery.fn.nextOrFirst = function (selector) {
      var next = this.next(selector);
      return next.length ? next : this.prevAll(selector).last();
    };

    // gamepiece carousel

    const gamepieces = $('.gamepieces .gamepiece:not(.invisible)');
    gamepieces.first().addClass('active');

    setInterval(() => {
      gamepieces
        .filter('.active')
        .removeClass('active')
        .nextOrFirst(':not(.invisible)')
        .addClass('active');
    }, 4000);

    // gameboard

    $('.gameboard-aspect-ratio')
      .on('mouseover', e => {
        const gameboard = $(e.currentTarget);
        const rooms1 = gameboard.find('.gameboard--rooms-1 img');
        const rooms2 = gameboard.find('.gameboard--rooms-2 img');
        const tm1 = TweenMax.to(rooms1, 60, {
          rotation: 360,
          ease: 'none',
          repeat: -1
        });
        const tm2 = TweenMax.to(rooms2, 45, {
          rotation: 360,
          ease: 'none',
          repeat: -1
        });
        gameboard.data('tm1', tm1);
        gameboard.data('tm2', tm2);
      })
      .on('mouseout', e => {
        const gameboard = $(e.currentTarget);
        const rooms1 = gameboard.find('.gameboard--rooms-1 img');
        const rooms2 = gameboard.find('.gameboard--rooms-2 img');
        gameboard.data('tm1')?.pause();
        gameboard.data('tm2')?.pause();
        TweenMax.to(rooms1, 0.5, { rotation: 0, ease: Power3.easeOut });
        TweenMax.to(rooms2, 0.5, { rotation: 0, ease: Power3.easeOut });
      });

    // scroll listener

    new ScrollListener(() => {
      const scrollAmount = $(window).scrollTop();
      $('body').toggleClass('scrolled', scrollAmount > 100);
    });
    $(window).scroll();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
