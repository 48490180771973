// turbolinks loading transitions

export default {

    init: () => {

        if ( typeof Turbolinks !== 'undefined' && Turbolinks.supported ) {

            $(document).on('turbolinks:click', e => {
                $('html').addClass('loading');
            });
        
            $(document).on('turbolinks:render ready', e => {
                $('html').removeClass('loading');
            });
    
        }

    }

}
