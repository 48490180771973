import { sha256 } from 'js-sha256'

export default {
  init() {
    const forms = $('form.ajax')
    
    // send FB event on email signup

    forms
      .each((index, el) => {
        const form = $(el)
        const params = new URLSearchParams(location.search)

        if ( params.get('utm_source') ) {
          form.prepend(`<input type="hidden" name="utm_source" value="${params.get('utm_source')}">`)
        }
      })
      .on('submit', (e) => {
        const email = $(e.currentTarget).find(':input[name=email]').val()
        const utm_source = $(e.currentTarget).find(':input[name=utm_source]').val()
        if ( email && utm_source === 'FB' ) {
          const PIXEL_ID = '789460542072368'
          const TOKEN = 'EAASyfbRzuS4BAPf3KEKvepodS4jmIaVcvhU2HGkqU5oLmYOxY3acwqQKr7If0iOblut2GYOAykVz2Pi3vjJ4HhZCcnQNpwJZBCELfrvZBUU9ZBnjXXkFClDQy0Ki0pJlMQIvTp99LimR7i78UwTVyZCKuYmrZCMSdBC0poqwtVUGl70YCVcK0O'
          const API_VERSION = 'v14.0'
          $.post(
            `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}`,
            {
              //"test_event_code": "TEST28810",
              "data": [
                  {
                      "event_name": "Subscription",
                      "event_time": Math.floor((new Date()).getTime() / 1000),
                      "action_source": "website",
                      "user_data": {
                          "em": [
                            sha256(email)
                          ]
                      }
                  }
              ]
          }
          )
        }
      })
  }
}